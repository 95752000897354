import React, {useState} from 'react';
import axios from "axios";
import InputMask from "react-input-mask";
import { ClipLoader } from 'react-spinners';
import 'animate.css';
import './App.css';

String.prototype.replaceAt = function(index, replacement) {
  return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}

function App() {
  const [creditCardNum, setCreditCardNum] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [messageColor, setMessageColor] = useState('green');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [amount, setAmount] = useState((0.00).toFixed(2));
  const [associateLote, setAssociateLote] = useState((0.00).toFixed(2));
  const [associateType, setAssociateType] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [docNumber, setDocNumber] = useState('');
  const [cardSecurityCode, setcardSecurityCode] = useState('');
  const [expireMonth, setExpireMonth] = useState('');
  const [expireYear, setExpireYear] = useState('');
  
  const handleNum = (e) => {
    setCreditCardNum(e.target.value);
    console.log(e.target.value);
  }
  
  const handleCardHolder = (e) => {
    setCardHolder(e.target.value);
  }

  const handleExpMonth = (e) => {
    setExpireMonth(e.target.value);
  }

  const handleSecCode = (e) => {
    setcardSecurityCode(e.target.value);
  }

  const handleExpYear = (e) => {
    setExpireYear(e.target.value);
  }

  const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  const handleCpf = (e) => {
    setCpf(cpfMask(e.target.value));
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleName = (e) => {
    setName(e.target.value);
  }

  // const handlePhone = (e) => {
  //   setPhone(e.target.value);
  // }

  const handleDocNumber = (e) => {
    setDocNumber(e.target.value);
  }

  const handleAssociateType = (e) => {
    setAssociateType(e.target.value);

    if(e.target.value === 'null'){
      setAssociateType(null);
      setAmount((0.00).toFixed(2));
    }

    var today = new Date();

    var lote = '';

    var primeiroLoteStartDate = new Date(`2023-07-01`);
    var primeiroLoteEndtDate = new Date(`2023-07-31`);

    var segundoLoteStartDate = new Date(`2023-08-01`);
    var segundoLoteEndDate = new Date(`2023-09-10`);

    var terceiroLoteStartDate = new Date(`2023-09-11`);
    var terceiroLoteEndDate = new Date(`2023-09-27`);

    if (today > primeiroLoteStartDate  && today < primeiroLoteEndtDate){
      lote = 'Primeiro'
    }
    else if (today > segundoLoteStartDate && today < segundoLoteEndDate){
      lote = 'Segundo'
    }
    else if (today > terceiroLoteStartDate && today < terceiroLoteEndDate){
      lote = 'Terceiro'
    }

    setAssociateLote(lote);

    if (e.target.value === 'Associado'){
      if (lote === 'Primeiro'){
        setAmount((50.00).toFixed(2));
      }
      else if (lote === 'Segundo'){
        setAmount((75.00).toFixed(2));
      }
      else if (lote === 'Terceiro'){
        setAmount((100.00).toFixed(2));
      }
    }
    else if (e.target.value === 'Estudantes'){
      if (lote === 'Primeiro'){
        setAmount((75.00).toFixed(2));
      }
      else if (lote === 'Segundo'){
        setAmount((100.00).toFixed(2));
      }
      else if (lote === 'Terceiro'){
        setAmount((125.00).toFixed(2));
      }
    }
    else if (e.target.value === 'Profissionais'){
      if (lote === 'Primeiro'){
        setAmount((100.00).toFixed(2));
      }
      else if (lote === 'Segundo'){
        setAmount((125.00).toFixed(2));
      }
      else if (lote === 'Terceiro'){
        setAmount((150.00).toFixed(2));
      }
    }
    else if (e.target.value === 'Transm.Online'){
      if (lote === 'Primeiro'){
        setAmount((30.00).toFixed(2));
      }
      else if (lote === 'Segundo'){
        setAmount((40.00).toFixed(2));
      }
      else if (lote === 'Terceiro'){
        setAmount((50.00).toFixed(2));
      }
    }
    else if (e.target.value === 'Doacao'){
      setAmount((10.00).toFixed(2));
    }
    else if (e.target.value === 'Anuidade'){
      setAmount((120.00).toFixed(2));
    }
  }

  const handleSubmit = (e) => {
    setLoading(true);
    setResponseMessage('');
    e.preventDefault();

    if (!docNumber && !cpf){
      setResponseMessage('Pelo menos um dos documentos de identificação devem ser preenchidos');
      setMessageColor('red');
      setLoading(false);
      return;
    }
    
    const api = axios.create();

    api.post(`https://api-pagamentos-abcf.azurewebsites.net/api/Transactions`, {
      userEmail: email,
      // phone: phone,
      cpf: cpf,
      docNumber: docNumber,
      name: name,
      amount: amount,
      type: associateType,
      associateType: associateType,
      lote: associateLote,
      installments: 1,
    }, {
      params: {
        paymentType: 2,
        cardNumber: creditCardNum,
        cardHolder: cardHolder,
        cardExpiration: `${expireMonth}/${expireYear}`,
        cardSecurityCode: cardSecurityCode,
        apiToken: process.env.REACT_APP_API_TOKEN
      }
    }).then((promisse) => {
      console.log(promisse.response);
      setMessageColor('green');
      setResponseMessage('Pagamento Efetuado com sucesso!');
      setName('');
      // setPhone('');
      setCpf('');
      setEmail('');
      setCardHolder('');
      setCreditCardNum('');
      setExpireMonth('');
      setExpireYear('');
      setcardSecurityCode('');
      setLoading(false);
      setAmount((0.00).toFixed(2));
      setAssociateType('');
      setAssociateLote('');
    }).catch((promisse) => {
      console.log(promisse.response);
      setMessageColor('red');
      setResponseMessage(promisse.response.data);
      setLoading(false);
    });
  }

  return (
       <div className="container">
        <form id="form" onSubmit={handleSubmit}>
          <a href="https://www.criancafeliz.org/" target='blank'><h3 style={{textAlign: 'center'}}>Pagamento ABCF (ir para website)</h3></a>
          <a href="https://www.criancafeliz.org/" target='blank'><div className="logo-container" style={{textAlign: 'center'}}>
            <img src="/logo-abcf.png" alt="Logo ABCF" width={100} />
          </div></a>
          <br />
          <div className="input-grp-two">
            <div className="input-container">
                <h4>Nome Completo</h4>
                <input disabled={loading} value={name} maxLength={50} type="text" onChange={handleName} placeholder="Insira o seu nome" required/>
            </div>

            <div className="input-container">
                <h4>Email</h4>
                <input disabled={loading} value={email} maxLength={50} type="email" onChange={handleEmail} placeholder="Insira o seu email" required/>
            </div>
          </div>
          <div className="input-grp-two">
            <div className="input-container">
                <h4>CPF (BR)</h4>
                <input 
                  maxLength='14'
                  value={cpf}
                  onChange={handleCpf}
                  placeholder="Insira o CPF"
                  disabled={loading}
                />
            </div>
            <div className="input-container">
                <h4>N° Documento (Estrangeiro)</h4>
                <input 
                  maxLength='14'
                  value={docNumber}
                  onChange={handleDocNumber}
                  placeholder="N° Do documento"
                  disabled={loading}
                />
            </div>
          </div>
          <hr />
          <br />
          <div className="input-container">
              <h4>Tipo de inscrição</h4>
                <select disabled={loading} value={associateType} required onChange={handleAssociateType}>
                  <option value='null'>Selecione o tipo</option>
                  <option value="Anuidade">Anuidade ABCF 2023</option>
                  <option value="Associado">Evento - Associado Efetivo Da ABCF</option>
                  <option value="Estudantes">Evento - Estudante Em Geral</option>
                  <option value="Profissionais">Evento - Profissionais Em Geral</option>
                  <option value="Transm.Online">Evento - Transmissão Online</option>
                  <option value="Doacao">Doação - Mínimo (BRL) R$10,00</option>
                </select>
          </div>
          <h3 style={{marginBottom: '10px'}}>Valor: R${amount}</h3>
          <div className="input-grp-two">
            <div className="input-container">
                <h4>Número do cartão</h4>
                <InputMask
                    mask='9999 9999 9999 9999'
                    disabled={loading}
                    value={creditCardNum}
                    onChange={handleNum}
                    placeholder="Insira o número do cartão de crédito"
                  />
              </div>

              <div className="input-container">
                  <h4>Nome no cartão</h4>
                  <input disabled={loading} value={cardHolder} onChange={handleCardHolder} maxLength={50} type="text" placeholder="Insira o nome no seu cartão" required/>
              </div>
          </div>
          <div className="input-grp">
              <div className="input-container">
                  <h4>Mês</h4>
                  <select disabled={loading} value={expireMonth} required onChange={handleExpMonth}>
                    <option>Selecione o mês</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
              </div>
              <div className="input-container">
                <h4>Ano</h4>
                  <select disabled={loading} value={expireYear} required onChange={handleExpYear}>
                      <option>Selecione o ano</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2031">2031</option>
                      <option value="2032">2032</option>
                      <option value="2033">2033</option>
                      <option value="2034">2034</option>
                      <option value="2035">2035</option>
                      <option value="2036">2036</option>
                      <option value="2037">2037</option>
                      <option value="2038">2038</option>
                      <option value="2039">2039</option>
                      <option value="2040">2040</option>
                    </select>
              </div>
              <div className="input-container">
                  <h4>CVV</h4>
                  <InputMask mask='999' disabled={loading} value={cardSecurityCode} placeholder="CVV" required onChange={handleSecCode}/>
              </div>
          </div>
          
          <label style={{color: messageColor}}>{responseMessage}</label>
          {loading ? <div style={{textAlign: 'center'}}><ClipLoader color="#000273" /></div> : <button disabled={loading} type='submit'>{`Pagar`}</button>}
            
        </form>
    </div>
  );
}

export default App;
